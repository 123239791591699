
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { OrderBaseDetail } from '@/types/plan'
import { FileInfo } from '@/types/common'

@Component({
  filters: {
    imgListFormat (list: Array<FileInfo>) {
      const imgList = list.map(item => {
        return item.filePrefix + item.fileUrl
      })
      return imgList || []
    }
  }
})
export default class Close extends Vue {
  @Prop() readonly orderShow!: boolean;
  @Prop() readonly taskId!: string;

  // 获取项目下所有人信息
  @Watch('taskId', { immediate: true })
  getProjectUserList (val: string) {
    if (val !== '') {
      this.loading = true
      this.$axios.get(this.$apis.plan.selectFlowPlansTaskById, {
        taskId: this.taskId
      }).then(res => {
        this.taskInfo = res
      }).finally(() => {
        this.loading = false
      })
    }
  }

  private statusList = ['待反馈', '待审核', '待反工', '已完成', '已关闭']
  private handleTypeList = [{
    icon: '#iconicon_zhuanban',
    class: 'transfer',
    status: '转办'
  }, {
    icon: '#iconicon_fankui',
    class: 'feedback',
    status: '反馈'
  }, {
    icon: '#iconicon_shenhe',
    class: 'audit',
    status: '审核'
  }, {
    icon: '#iconicon_guanbi',
    class: 'close',
    status: '关闭'
  }]

  private loading = false
  private taskInfo: OrderBaseDetail | {} = {}

  getRowKeys (row: any) {
    return row.orderId
  }

  getHandleList (row: OrderBaseDetail) {
    if (!(row.handleList && row.handleList.length > 0)) {
      this.$axios.get(this.$apis.plan.selectFlowTaskOrderByOrderId, {
        orderId: row.orderId
      }).then(res => {
        row.handleList = res
      })
    }
  }

  // 关闭
  closeDialog () {
    this.$emit('update:orderShow', false)
  }
}
