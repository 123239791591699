
import { Component, Vue } from 'vue-property-decorator'
import Order from './Order.vue'
import { KeepAlive } from '@/utils/decorators'

@Component({
  name: 'taskDetails',
  components: { Order },
  filters: {
    orderStatus: function (value: any) {
      if (value === '1') {
        return '待反馈'
      } else if (value === '2') {
        return '待审核'
      } else if (value === '3') {
        return '待返工'
      } else if (value === '4') {
        return '已完成'
      } else if (value === '5') {
        return '已关闭'
      } else {
        return '--'
      }
    }
  }
  // components: { }
})
@KeepAlive
export default class TaskDetails extends Vue {
  private orderShow = false
  private taskId = ''

  private statusList = ['待反馈', '待审核', '待返工', '已完成', '已关闭']
  private taskStatus = [
    {
      value: '1',
      label: '未下发'
    },
    {
      value: '2',
      label: '已下发'
    }
  ]

  private date = []
  private searchInfo = {
    projectId: '', // 项目id（非模糊查询）
    tStatus: '', // 任务状态
    orderStatus: '', // 工单状态
    introduce: '', // 计划说明，父页面带过来
    projectName: '', // 项目名称（模糊查询），父页面带过来
    planMonth: '', // 月份，父页面带过来
    taskStatus: '', // 父页面带过来（点击下发状态时带这个参数）
    isFinished: '' // 父页面带过来（点击完成状态时带这个参数）
  }

  private loading = false
  private tableData = []
  page = 1
  size = 10
  total = 0

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    // console.log('参数', this.$route.query)
    this.searchInfo.introduce = this.$route.query.introduce as any
    this.searchInfo.projectName = this.$route.query.projectName as any
    this.searchInfo.planMonth = this.$route.query.planMonth as any
    this.searchInfo.taskStatus = this.$route.query.taskStatus as any
    this.searchInfo.isFinished = this.$route.query.isFinished as any
    this.getData()
  }

  getData () {
    this.loading = true
    const ctime = this.date && this.date.length ? {
      startTime: this.date[0],
      endTime: this.date[1]
    } : {}
    this.$axios.get(this.$apis.plan.selectFlowPlansTaskPage, {
      ...this.searchInfo,
      ...ctime,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      res.list = res.list.map((item: any) => {
        item.userObj = JSON.parse(item.userObj).map((k: any, index: any) => {
          return index === JSON.parse(item.userObj).length - 1 ? k.currentProcessorName : k.currentProcessorName + '、'
        })
        return item
      })
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onDetail (taskId: string) {
    // console.log('详情')
    this.taskId = taskId
    this.orderShow = true
  }

  onSearch () {
    this.page = 1
    this.getData()
  }
}
